import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined, ProfileOutlined, DollarOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import paymentPlanModal from './paymentPlanModal.vue';
import { selectContractByParam, selectNextPaymentNode, insertPaymentApply } from '@/api/contract';
import { selectApprovalItem, selectProfessionalByItem } from '@/api/material';
import moment from 'moment';
import Cookies from "js-cookie";
export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    uploadImgs,
    ProfileOutlined,
    DollarOutlined,
    paymentPlanModal
  },
  props: {
    applyVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const formState = reactive({
      theme: "",
      projectName: undefined,
      contractName: '',
      belongProfessional: undefined,
      fileUrl: [],
      payNode: [],
      totalAmount: null,
      contractApplyId: undefined,
      itemId: undefined
    });
    const {
      checkName,
      checkPhone,
      signFileurl,
      money
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      projectName: [{
        required: true,
        message: '项目名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      contractName: [{
        required: true,
        message: '合同名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      fileUrl: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        // trigger: 'change',
        trigger: formState.fileUrl.length == 0
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    }; //获取附件的url

    const getAdjuncts = val => {
      console.log('getAdjuncts', val);
      formState.fileUrl = val;
    };

    const loading = ref(false); //提交

    const handleOk = () => {
      // console.log(formState)
      formRef.value.validate().then(async () => {
        if (!formState.payNode.length) {
          _message.error('此合同无付款节点,不可提交');

          return;
        }

        const obj = {};
        Object.assign(obj, formState);
        obj.payNode = JSON.stringify(obj.payNode);
        obj.fileUrl = JSON.stringify(obj.fileUrl);
        obj.belongProfessional = obj.belongProfessional ? obj.belongProfessional.toString() : null;
        console.log(obj);
        loading.value = true;
        let res = await insertPaymentApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    }; //项目名称查询


    const projectNameData = ref([]);
    const contractNameData = ref([]);

    const handleSearch = val => {
      fetchForMat(val, null);
    };

    const handleChange = index => {
      formState.contractName = undefined;
      formState.payNode = [];
      formState.totalAmount = null;
    }; //合同名查询


    const handleSearchForCot = val => {
      fetchForMat1(formState.projectName, val);
    };

    const fetchForMat = async (projectName, contractName) => {
      let res = await selectContractByParam({
        projectName,
        contractName,
        type: 2
      }); // console.log(res)

      try {
        projectNameData.value = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            projectNameData.value.push({
              value: ixt.projectName,
              text: ixt.projectName
            });
          });
        }

        projectNameData.value = uniqueJsonArray(projectNameData.value, 'text');
      } catch (error) {
        console.log(error);
      }
    };

    const fetchForMat1 = async (projectName, contractName) => {
      let res = await selectContractByParam({
        projectName,
        contractName,
        type: 2
      }); // console.log(res)

      try {
        contractNameData.value = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            contractNameData.value.push({
              value: ixt.contractName,
              text: ixt.contractName,
              applyId: ixt.id
            });
          });
        }
      } catch (error) {
        console.log(error);
      }
    }; //去重


    const uniqueJsonArray = (array, key) => {
      for (var i = 0; i < array.length; i++) {
        for (var j = array.length - 1; j > 0; j--) {
          if (array[i][key] == array[j][key] && i != j) {
            array.splice(j, 1);
          }
        }
      }

      return array;
    }; //获取付款节点


    const handleChangeForCot = val => {
      // console.log(val)
      if (val) {
        formState.contractApplyId = contractNameData.value.find(item => item.value == val).applyId;
        getPaymentNode(formState.contractApplyId);
      } else {
        formState.payNode = [];
        formState.totalAmount = null;
      }
    };

    const getPaymentNode = async id => {
      const res = await selectNextPaymentNode({
        applyId: id,
        belongProject: Number(Cookies.get("belongProject"))
      });

      try {
        if (res.data.length) {
          formState.payNode = [];
          formState.totalAmount = null;
          res.data.forEach(item => {
            let obj = {
              nodeDesc: item.nodeDesc,
              payTerms: item.payTerms,
              //payAmount: item.payAmount,
              id: item.id,
              contractApplyId: item.contractApplyId,
              changeApplyId: item.changeApplyId,
              totalAmount: item.totalAmount,
              actualPayAmount: item.payAmount,
              planPayTime: item.planPayTime
            };
            formState.totalAmount += item.payAmount;
            formState.payNode.push(obj);
          });
        } else {
          _message.error('暂无付款节点');
        }
      } catch (error) {
        console.log(error);
      }
    }; //修改付款节点后自动修改总金额


    const handleChangePayAmound = () => {
      formState.totalAmount = null;
      formState.payNode.forEach(item => {
        formState.totalAmount += item.actualPayAmount;
      });
    };

    const planVisible = ref(false); //关闭收支计划弹窗

    const closePlanVisible = val => {
      planVisible.value = val;
    }; //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 6,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //所属专业


    const professionalOptions = ref([]);

    const getProfessionalByItem = async () => {
      let res = await selectProfessionalByItem({
        itemId: formState.itemId
      });

      if (res.code === 200) {
        professionalOptions.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            professionalOptions.value.push({
              value: item.professionalName,
              label: item.professionalName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getApprovalItem();
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      handleOk,
      loading,
      getAdjuncts,
      // disabledDate,
      handleSearch,
      projectNameData,
      money,
      handleSearchForCot,
      contractNameData,
      handleChange,
      handleChangeForCot,
      handleChangePayAmound,
      closePlanVisible,
      planVisible,
      projectOptions,
      professionalOptions,
      getProfessionalByItem
    };
  }

});